import { useState } from "react";
import AboutTab from "./about";
import ContactTab from "./Contact";
import ProjectsTab from "./Projects";

const LandingTabs = () => {
  const tabs = { ABOUT: "ABOUT", PROJECTS: "PROJECTS", CONTACT: "CONTACT" };
  const [activeTab, setActiveTab] = useState(tabs.ABOUT);
  const ACTIVE_TAB_CLASS =
    "inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500";
  const IN_ACTIVE_TAB_CLASS =
    "inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300";
  return (
    <>
    <ul class="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
      {Object.values(tabs).map((tabLabel, index) => (
        <li class="me-2">
          <span
            href="#"
            aria-current="page"
            class={`${
              activeTab === tabLabel ? ACTIVE_TAB_CLASS : IN_ACTIVE_TAB_CLASS
            } cursor-pointer`}
            onClick={() => setActiveTab(tabLabel)}
          >
            {tabLabel}
          </span>
        </li>
      ))}
    </ul>
    {
        activeTab === tabs.ABOUT  ? <AboutTab /> : <></>
    }
        {
        activeTab === tabs.PROJECTS  ? <ProjectsTab /> : <></>
    }
        {
        activeTab === tabs.CONTACT  ? <ContactTab /> : <></>
    }
    </>
  );
};

export default LandingTabs;
