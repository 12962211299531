import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-white">
    {/* <div className="mx-auto max-w-screen-xl px-4 pb-6 pt-16 sm:px-6 lg:px-8 lg:pt-24">
      <div className="mt-12 border-t border-gray-100 pt-6">
        <div className="text-center sm:flex sm:justify-between sm:text-left">
          <p className="text-sm text-gray-500">
            <Link
              className="inline-block font-bold text-gray-500  transition hover:text-teal-600/75"
              to={"/privacy-policy"}
            >
              Privacy Policy
            </Link>
          </p>

          <p className="mt-4 text-sm text-gray-500 sm:order-first sm:mt-0">
            Coming with more cool pieces of stuff
          </p> 
        </div>
      </div>
    </div> */}
  </footer>
  );
};


export default Footer;