import React from "react";
import { FaTwitter, FaLinkedin, FaMedium, FaGithub } from "react-icons/fa";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <nav className="fixed z-50 w-full bg-white top-0 flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg shadow-lg">
      <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
        <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
          <Link
            className="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-no-wrap uppercase text-gray-800"
            to="/"
          >
            Tushar Singh
          </Link>
          <button
            className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
            type="button"
          >
            <i className="fas fa-bars"></i>
          </button>
        </div>
        <div
          className="lg:flex flex-grow items-center hidden"
          id="example-navbar-danger"
        >
          <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
            <li className="nav-item">
              <Link
                to="https://x.com/tusharsingh1991"
                className="px-3 py-2 flex items-center text-xs uppercase font-bold text-gray-800 hover:text-gray-600"
                target="_blank"
              >
                <FaTwitter className="text-lg leading-lg text-gray-500" />
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="https://www.linkedin.com/in/tusharsingh1991/"
                className="px-3 py-2 flex items-center text-xs uppercase font-bold text-gray-800 hover:text-gray-600"
                target="_blank"
              >
                <FaLinkedin className="text-lg leading-lg text-gray-500" />
              </Link>
            </li>

            <li className="nav-item">
              <Link
                to="https://medium.com/@tusharsingh1991"
                className="px-3 py-2 flex items-center text-xs uppercase font-bold text-gray-800 hover:text-gray-600"
                target="_blank"
              >
                <FaMedium className="text-lg leading-lg text-gray-500" />
              </Link>
            </li>

            <li className="nav-item">
              <Link
                to="https://github.com/tusharsingh1991"
                className="px-3 py-2 flex items-center text-xs uppercase font-bold text-gray-800 hover:text-gray-600"
                target="_blank"
              >
                <FaGithub className="text-lg leading-lg text-gray-500" />
              </Link>
            </li>
            <li className="nav-item">
              <Link
              target="_blank"
                className="px-3 py-2 flex items-center text-xs uppercase  text-gray-800 hover:text-gray-600"
                to={`/assets/tusharsingh.pdf`}
              >
                <i className="far fa-file-alt text-lg leading-lg text-gray-500"></i>
                <span className="ml-2">Resume</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="download-button px-3 py-2 flex items-center text-xs uppercase  text-gray-800 hover:text-gray-600"
                to={"/resources"}
              >
                <i className="fas fa-arrow-alt-circle-down text-lg leading-lg text-gray-500"></i>
                <span className="ml-2">Resources</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={"/privacy-policy"}
                className="download-button px-3 py-2 flex items-center text-xs uppercase  text-gray-800 hover:text-gray-600"
              >
                <i className="fab fa-sketch text-lg leading-lg text-gray-500"></i>
                <span className="ml-2">Privacy Policy</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;