const ContactTab = () => {
    return (
      <div className="lg:pt-12 pt-8 w-full md:w-12/12 px-4">
        <h2 className="text-4xl font-normal">Contact</h2>
        <p className="text-lg leading-relaxed m-4 text-gray-600">
          Coming Soon...
        </p>
      </div>
    );
  };
  
  export default ContactTab;
  