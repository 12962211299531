import PrivacyPolicy from "../pages/PrivacyPolicy";
import Resources from "../pages/Resources";

const { createBrowserRouter } = require("react-router-dom");
const { default: Landing } = require("../pages/Landing");

const AppRouter = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
    exact: true
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/resources",
    element: <Resources />,
  }
]);

export default AppRouter;
