import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import LandingTabs from "../components/landingtabs";

const Landing = () => {
  return (
    <main>
      <Header />
      <main>
        <section className="pt-20 pb-48">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
              <div className="lg:pt-12 pt-6 w-full md:w-12/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8">
                  <div className="px-4 py-5 flex-auto">
                    <div className="flex flex-wrap justify-center items-center content-center ">
                      <div className="">
                        <img
                          src={
                            process.env.PUBLIC_URL + "assets/img/profilepicture.png"
                          }
                          height={200}
                          width={200}
                          alt="..."
                          className="shadow rounded-full h-auto align-middle border-none"
                        />
                      </div>
                    </div>
                    <h6 className="text-xl font-semibold">Tushar Singh</h6>
                    <p className="mt-2 mb-4 text-gray-600">
                      #AppDeveloper#TechConsultant#Crypto#Gaming
                    </p>
                  </div>
                </div>
              </div>
              <div className="lg:pt-2 pt-2 w-full md:w-12/12 px-4 text-center mt-2">
                <LandingTabs />
                         
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </main>
    </main>
  );
};

export default Landing;
