import React from "react";
import Navbar from "../components/Header";
import Footer from "../components/Footer";

const PrivacyPolicy = () => {
  return (
    <>
      <Navbar transparent />
      <main>
        <section className="pt-10 pb-48">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
              <div className="lg:pt-12 pt-12 w-full md:w-12/12 px-4 mt-12">
                <h2 className="text-4xl font-normal">Privacy Policy</h2>
                <p className="text-md leading-relaxed mt-4 text-gray-600">
                  This Privacy Policy describes Our policies and procedures on
                  the collection, use and disclosure of Your information when
                  You use the Service and tells You about Your privacy rights
                  and how the law protects You.
                </p>
              </div>
              <div className="lg:pt-8 pt-8 w-full md:w-12/12 px-4 mt-2">
                <h2 className="text-4xl font-normal">
                  Interpretation and Definitions
                </h2>
                <h2 className="text-2xl mt-6 font-normal">Interpretation</h2>
                <p className="text-md leading-relaxed mt-4 text-gray-600">
                  The words of which the initial letter is capitalized have
                  meanings defined under the following conditions. The following
                  definitions shall have the same meaning regardless of whether
                  they appear in singular or in plural.
                </p>
                <h2 className="text-2xl mt-6 font-normal">Definitions</h2>
                <p className="text-md leading-relaxed mt-4 text-gray-600">
                  For the purposes of this Privacy Policy:
                </p>
                <p className="text-md leading-relaxed mt-4 text-gray-600">
                  <div className="bullet-points">
                    <ul>
                      <li>
                        <b>Account</b> means a unique account created for You to
                        access our Service or parts of our Service.
                      </li>
                      <li>
                        <b>Affiliate</b> means an entity that controls, is
                        controlled by or is under common control with a party,
                        where "control" means ownership of 50% or more of the
                        shares, equity interest or other securities entitled to
                        vote for election of directors or other managing
                        authority.
                      </li>
                      <li>
                        <b>Company</b> (referred to as either "the Company",
                        "We", "Us" or "Our" in this Agreement) refers to Tushar
                        Singh.
                      </li>
                      <li>
                        <b>Cookies</b> are small files that are placed on Your
                        computer, mobile device or any other device by a
                        website, containing the details of Your browsing history
                        on that website among its many uses.
                      </li>
                      <li>
                        <b>Country</b> refers to: Uttar Pradesh, India
                      </li>
                      <li>
                        <b>Device</b> means any device that can access the
                        Service such as a computer, a cellphone or a digital
                        tablet.
                      </li>
                      <li>
                        <b>Personal Data</b> is any information that relates to
                        an identified or identifiable individual.
                      </li>
                      <li>
                        <b>Service refers</b> to the Website.
                      </li>
                      <li>
                        <b>Service Provider</b> means any natural or legal
                        person who processes the data on behalf of the Company.
                        It refers to third-party companies or individuals
                        employed by the Company to facilitate the Service, to
                        provide the Service on behalf of the Company, to perform
                        services related to the Service or to assist the Company
                        in analyzing how the Service is used.
                      </li>
                      <li>
                        <b>Third-party Social Media Service</b> refers to any
                        website or any social network website through which a
                        User can log in or create an account to use the Service.
                      </li>
                      <li>
                        <b>Usage Data</b> refers to data collected
                        automatically, either generated by the use of the
                        Service or from the Service infrastructure itself (for
                        example, the duration of a page visit).
                      </li>
                      <li>
                        <b>Website</b> refers to Tushar Singh, accessible from{" "}
                        <a href="https://tusharsingh.me">
                          https://tusharsingh.me
                        </a>
                      </li>
                      <li>
                        <b>You</b> means the individual accessing or using the
                        Service, or the company, or other legal entity on behalf
                        of which such individual is accessing or using the
                        Service, as applicable.
                      </li>
                    </ul>
                  </div>
                </p>
                <h2 className="text-4xl mt-6 font-normal">
                  Retention of Your Personal Data
                </h2>
                <p className="text-md leading-relaxed mt-4 text-gray-600">
                  The Company will retain Your Personal Data only for as long as
                  is necessary for the purposes set out in this Privacy Policy.
                  We will retain and use Your Personal Data to the extent
                  necessary to comply with our legal obligations (for example,
                  if we are required to retain your data to comply with
                  applicable laws), resolve disputes, and enforce our legal
                  agreements and policies.
                </p>
                <p className="text-md leading-relaxed mt-4 text-gray-600">
                  The Company will also retain Usage Data for internal analysis
                  purposes. Usage Data is generally retained for a shorter
                  period of time, except when this data is used to strengthen
                  the security or to improve the functionality of Our Service,
                  or We are legally obligated to retain this data for longer
                  time periods.
                </p>
                <h2 className="text-4xl mt-6 font-normal">
                  Transfer of Your Personal Data
                </h2>
                <p className="text-md leading-relaxed mt-4 text-gray-600">
                  Your information, including Personal Data, is processed at the
                  Company's operating offices and in any other places where the
                  parties involved in the processing are located. It means that
                  this information may be transferred to — and maintained on —
                  computers located outside of Your state, province, country or
                  other governmental jurisdiction where the data protection laws
                  may differ than those from Your jurisdiction.
                </p>
                <p className="text-md leading-relaxed mt-4 text-gray-600">
                  Your consent to this Privacy Policy followed by Your
                  submission of such information represents Your agreement to
                  that transfer.
                </p>
                <p className="text-md leading-relaxed mt-4 text-gray-600">
                  The Company will take all steps reasonably necessary to ensure
                  that Your data is treated securely and in accordance with this
                  Privacy Policy and no transfer of Your Personal Data will take
                  place to an organization or a country unless there are
                  adequate controls in place including the security of Your data
                  and other personal information.
                </p>
                <h2 className="text-4xl mt-6 font-normal">
                  Delete Your Personal Data
                </h2>
                <p className="text-md leading-relaxed mt-4 text-gray-600">
                  You have the right to delete or request that We assist in
                  deleting the Personal Data that We have collected about You.
                </p>
                <p className="text-md leading-relaxed mt-4 text-gray-600">
                  Our Service may give You the ability to delete certain
                  information about You from within the Service.
                </p>
                <p className="text-md leading-relaxed mt-4 text-gray-600">
                  You may update, amend, or delete Your information at any time
                  by signing in to Your Account, if you have one, and visiting
                  the account settings section that allows you to manage Your
                  personal information. You may also contact Us to request
                  access to, correct, or delete any personal information that
                  You have provided to Us.
                </p>
                <p className="text-md leading-relaxed mt-4 text-gray-600">
                  Please note, however, that We may need to retain certain
                  information when we have a legal obligation or lawful basis to
                  do so.
                </p>
                <h2 className="text-4xl mt-6 font-normal">
                  Disclosure of Your Personal Data
                </h2>
                <h2 className="text-2xl mt-6 font-normal">
                  Business Transactions
                </h2>
                <p className="text-md leading-relaxed mt-4 text-gray-600">
                  If the Company is involved in a merger, acquisition or asset
                  sale, Your Personal Data may be transferred. We will provide
                  notice before Your Personal Data is transferred and becomes
                  subject to a different Privacy Policy.
                </p>
                <h2 className="text-2xl mt-6 font-normal">Law enforcement</h2>
                <p className="text-md leading-relaxed mt-4 text-gray-600">
                  Under certain circumstances, the Company may be required to
                  disclose Your Personal Data if required to do so by law or in
                  response to valid requests by public authorities (e.g. a court
                  or a government agency).
                </p>
                <h2 className="text-2xl mt-6 font-normal">
                  Other legal requirements
                </h2>
                <p className="text-md leading-relaxed mt-4 text-gray-600">
                  The Company may disclose Your Personal Data in the good faith
                  belief that such action is necessary to:
                </p>
                <div className="bullet-points">
                  <ul>
                    <li>Comply with a legal obligation</li>
                    <li>
                      Protect and defend the rights or property of the Company
                    </li>
                    <li>
                      Prevent or investigate possible wrongdoing in connection
                      with the Service
                    </li>
                    <li>
                      Protect the personal safety of Users of the Service or the
                      public
                    </li>
                    <li>Protect against legal liability</li>
                  </ul>
                </div>
                <h2 className="text-2xl mt-6 font-normal">
                  Security of Your Personal Data
                </h2>
                <p className="text-md leading-relaxed mt-4 text-gray-600">
                  The security of Your Personal Data is important to Us, but
                  remember that no method of transmission over the Internet, or
                  method of electronic storage is 100% secure. While We strive
                  to use commercially acceptable means to protect Your Personal
                  Data, We cannot guarantee its absolute security.
                </p>
                <h2 className="text-4xl mt-6 font-normal">
                  Children's Privacy
                </h2>
                <p className="text-md leading-relaxed mt-4 text-gray-600">
                  Our Service does not address anyone under the age of 13. We do
                  not knowingly collect personally identifiable information from
                  anyone under the age of 13. If You are a parent or guardian
                  and You are aware that Your child has provided Us with
                  Personal Data, please contact Us. If We become aware that We
                  have collected Personal Data from anyone under the age of 13
                  without verification of parental consent, We take steps to
                  remove that information from Our servers.
                </p>
                <p className="text-md leading-relaxed mt-4 text-gray-600">
                  If We need to rely on consent as a legal basis for processing
                  Your information and Your country requires consent from a
                  parent, We may require Your parent's consent before We collect
                  and use that information.
                </p>
                <h2 className="text-4xl mt-6 font-normal">
                  Links to Other Websites
                </h2>
                <p className="text-md leading-relaxed mt-4 text-gray-600">
                  Our Service may contain links to other websites that are not
                  operated by Us. If You click on a third party link, You will
                  be directed to that third party's site. We strongly advise You
                  to review the Privacy Policy of every site You visit.
                </p>
                <p className="text-md leading-relaxed mt-4 text-gray-600">
                  We have no control over and assume no responsibility for the
                  content, privacy policies or practices of any third party
                  sites or services.
                </p>
                <h2 className="text-4xl mt-6 font-normal">
                  Changes to this Privacy Policy
                </h2>
                <p className="text-md leading-relaxed mt-4 text-gray-600">
                  We may update Our Privacy Policy from time to time. We will
                  notify You of any changes by posting the new Privacy Policy on
                  this page.
                </p>
                <p className="text-md leading-relaxed mt-4 text-gray-600">
                  We will let You know via email and/or a prominent notice on
                  Our Service, prior to the change becoming effective and update
                  the &quot;Last updated&quot; date at the top of this Privacy
                  Policy.
                </p>
                <p className="text-md leading-relaxed mt-4 text-gray-600">
                  You are advised to review this Privacy Policy periodically for
                  any changes. Changes to this Privacy Policy are effective when
                  they are posted on this page.
                </p>
                <h2 className="text-4xl mt-6 font-normal">Contact Us</h2>
                <p className="text-md leading-relaxed mt-4 text-gray-600">
                  If you have any questions about this Privacy Policy, You can
                  contact us:
                </p>
                <ul>
                  <li>By email : tusharsingh44@gmail.com </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </main>
    </>
  );
};
export default PrivacyPolicy;
