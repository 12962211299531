import { FaTwitter, FaLinkedin, FaMedium, FaGithub } from "react-icons/fa";
import { Link, RouterProvider } from "react-router-dom";
import AppRouter from "./helpers/AppRoutes";

function App() {
  return (
    <>
      <RouterProvider router={AppRouter} />
    </>
  );
}

export default App;
