import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Resources = () => {
  return (
    <main>

<Header />
    <section className="pt-20 pb-48">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap">
          <div className="lg:pt-12 pt-6 w-full md:w-12/12 px-4 text-center">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8">
              <div className="px-4 py-5 flex-auto">
                <h6 className="text-xl font-semibold">Coming Soon...</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </main>
  );
};

export default Resources;
