const ProjectsTab = () => {
    return (
        <main>
        <section className="pt-20 pb-48">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
              <div className="lg:pt-2 pt-2 w-full md:w-12/12 px-4 text-center mt-2">
              <h2 className="text-4xl font-normal">Projects</h2>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  };
  
  export default ProjectsTab;
  