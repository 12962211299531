const AboutTab = () => {
  return (
    <div className="lg:pt-12 pt-8 w-full md:w-12/12 px-4">
      <h2 className="text-4xl font-normal">Biography</h2>
      <p className="text-lg leading-relaxed m-4 text-gray-600">
        Steeped in the dynamic realm of software engineering, I am a seasoned
        professional dedicated to crafting elegant solutions in the
        ever-evolving landscape of technology. With a fervent passion for
        innovation and a penchant for problem-solving, I traverse the intricate
        web of code with precision and creativity, driven by a relentless
        pursuit of excellence
      </p>
      <p className="text-lg leading-relaxed m-4 text-gray-600">
        Armed with a comprehensive understanding of software development
        methodologies and a diverse skill set honed over years of hands-on
        experience, I approach each project with a unique blend of technical
        expertise and strategic insight. From architecting scalable systems to
        optimizing performance, my journey as a software professional is defined
        by a commitment to delivering high-quality solutions that exceed
        expectations and drive tangible results.
      </p>
      <p className="text-lg leading-relaxed m-4 text-gray-600">
        With a keen eye for detail and a steadfast commitment to continuous
        learning, I thrive in fast-paced environments where innovation thrives
        and challenges abound. Whether collaborating with cross-functional teams
        or diving deep into lines of code, I embrace every opportunity to push
        the boundaries of what's possible and unlock new opportunities for
        growth and advancement.
      </p>
    </div>
  );
};

export default AboutTab;
